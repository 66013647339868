import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import logobi from './img/logoCM.png'
// import Footer from './Footer';

const Login = () => {
  const [user,setUser] =useState("")
    const [pass,setPass] =useState("")
    let navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('loggedIn');
      }, []);

    const ingresar = () => {
        
        if(user==='63203' && pass==='63203'){
            navigate('/', { replace: true });
            localStorage.setItem('loggedIn', 'true');
        }else{
            toast.warn("Credenciales incorrectas!");
        }
       
    }
    return (
        <main id="mainLogin" className='bg-biAzul'>
            <div className='container-fluid'>
                <div className="row animate__animated animate__fadeIn justify-content-center">
                    <div className="card  md-login">
                        <div className="card-body">
                            <div className="row mt-3 text-center">
                                <span><img src={logobi} className='w-logo-login' alt=""  /></span>
                            </div>
                             
                            <div className="row mt-5">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1"><i className="colorIconAqua bi bi-person-fill"></i></span>
                                    <input type="text" onChange={(e) => setUser(e.target.value)} required value={user} className="form-control" />
                                </div>

                            </div>
                            <div className="row mt-2">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1"><i className="colorIconAqua bi bi-lock-fill"></i></span>
                                    <input type="password" onChange={(e) => setPass(e.target.value)} required value={pass} className="form-control" />
                                </div>

                            </div>
                            <div className="row text-center mt-4 mb-3">
                                <div className="col">
                                    <button type='button' onClick={ingresar} className='btn btn-primary plecaBI'>Ingresar</button>
                                </div>
                            </div>
                       
                        </div>
                    </div>
                    <div className="row text-center text-white mt-3">
                        <span>Diseñado por <b>Aplicaciones Internas </b><br /><b>DATACENTRO S.A.</b><br /><b>1.0</b></span>
                    </div>
                </div>


                {/* <button onClick={ingresar} className='btn btn-success'>Ingresar</button> */}
            </div>
            {/* <Footer/> */}
            <ToastContainer 
                autoClose={2000}
            />
            {/* <Footer /> */}
        </main>
        
    )
}

export default Login