import {tipoDocumentos} from './Data/Administracion'

const TipoDocumentos = () => {
  return (
    <main id="main" className="main">
    <div className='container-fluid'>
      <div className="row mt-2 text-start">
        <div className="pagetitle">
          <h1>Tipos Documentos</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index.html">Tipos Documentos</a></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <div className='row'>
      <div className="col">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Tipo Documento</th>
              <th scope="col">Descripción</th>
              <th scope="col">Documento Global</th>
              <th scope="col">Fecha Vencimiento</th>
            </tr>
          </thead>
          <tbody>
            {tipoDocumentos.map((td, index) => (
              <tr key={index}>
                <td><button className='btn btn-primary btn-sm'>{td.id}</button></td>
                <td>{td.nombre}</td>
                <td>{td.descripcion}</td>
                
                <td>{td.aplicaGlobal ? <span class="badge  bg-success "><i class=" bi bi-check-circle "></i></span> : <span class="badge bg-danger "><i class="bi bi-x-circle "></i></span>}</td>
                <td>{td.aplicaFechaVencimiento ? <span class="badge  bg-success "><i class=" bi bi-check-circle "></i></span> : <span class="badge bg-danger "><i class="bi bi-x-circle "></i></span>}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </main>
  )
}

export default TipoDocumentos