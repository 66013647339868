import { Link } from 'react-router-dom';
import logobi from './img/logoCM.png'

const Slider = () => {
    return (
        <div>

            <header id="header" className="header fixed-top d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-between">
                    <Link to="Inicio" className="logo d-flex align-items-center">
                        <img src={logobi} alt="" />
                    </Link>
                
                </div>
                <nav className="header-nav ms-auto">

                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown pe-3">
                            <Link className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <span className="d-none d-md-block dropdown-toggle ps-2">
                                    <i className="bi bi-person-fill"></i>
                                    63203</span>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>
                                        <i className="bi bi-person-fill"></i>Alejandro Ixcol Castro</h6>
                                    <span><i className="bi bi-gear"></i>Administrador</span>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="Login">
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>
            <aside id="sidebar" className="sidebar">
                <ul className="sidebar-nav text-start" id="sidebar-nav">
                    <li className="nav-heading">ADMINISTRACION</li>
                    <li class="nav-item">
                        <Link class="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                            <i class="bi bi-gear-fill"></i><span>Administración</span><i class="bi bi-chevron-down ms-auto"></i>
                        </Link>
                        <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <li>
                                <Link to="usuarios">
                                    <i class="bi bi-circle"></i><span>Usuarios</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="roles">
                                    <i class="bi bi-circle"></i><span>Roles</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="permisos">
                                    <i class="bi bi-circle"></i><span>Permisos</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="grupos">
                                    <i class="bi bi-circle"></i><span>Grupos</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link collapsed" data-bs-target="#Expediente" data-bs-toggle="collapse" href="#">
                            <i class="bi bi-folder-fill"></i><span>Expediente</span><i class="bi bi-chevron-down ms-auto"></i>
                        </Link>
                        <ul id="Expediente" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <li>
                                <Link to="tiposDocumentos">
                                    <i class="bi bi-circle"></i><span>Tipos Documentos</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="productos">
                                    <i class="bi bi-circle"></i><span>Productos</span>
                                </Link>
                            </li>
                           
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" href="index">
                        <i class="bi bi-diagram-3-fill"></i>
                            <span>Flujos</span>
                        </Link>
                    </li>
                    <li className="nav-heading">GENERAL</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" href="index">
                            <i class="bi bi-archive-fill"></i>
                            <span>Papelera</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" href="index">
                        <i class="bi bi-list-task"></i>
                            <span>Tareas&nbsp;&nbsp;<span class="badge bg-primary badge-number">4</span></span>
                        </Link>
                    </li>
                    
                    <li class="nav-item">
                        <Link class="nav-link collapsed" data-bs-target="#Solicitudes" data-bs-toggle="collapse" href="#">
                        <i class="bi bi-folder-plus"></i><span>Solicitudes</span><i class="bi bi-chevron-down ms-auto"></i>
                        </Link>
                        <ul id="Solicitudes" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <li>
                                <Link to="components-alerts">
                                    <i class="bi bi-circle"></i><span>Nueva Solicitud</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="components-accordion">
                                    <i class="bi bi-circle"></i><span>Solicitudes en proceso</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="components-accordion">
                                    <i class="bi bi-circle"></i><span>Solicitudes en Finalizadas</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" href="index">
                        <i class="bi bi-search"></i>
                            <span>Clientes</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Reportes</li>
                    <li class="nav-item">
                        <Link class="nav-link collapsed" data-bs-target="#Reporte" data-bs-toggle="collapse" href="#">
                        <i class="bi bi-bar-chart-fill"></i><span>Reportes</span><i class="bi bi-chevron-down ms-auto"></i>
                        </Link>
                        <ul id="Reporte" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <li>
                                <Link to="usuarios">
                                    <i class="bi bi-circle"></i><span>Solicitudes Finalizadas</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="components-accordion">
                                    <i class="bi bi-circle"></i><span>Documentos Vencidos</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="components-accordion">
                                    <i class="bi bi-circle"></i><span>Documentos Actualizados</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </aside>
        </div>
    )
}

export default Slider