// import NavBar from '../components/NavBar'
import {

    Routes,
    Route,
    Navigate,
    useNavigate
} from 'react-router-dom';
import { useEffect } from 'react';
import Inicio from '../components/Inicio';
import Slider from '../components/Slider';

import Roles from '../components/Roles';
import Usuarios from '../components/Usuarios';
import Permisos from '../components/Permisos';
import Grupos from '../components/Grupos';
import TipoDocumentos from '../components/TipoDocumentos';
import Productos from '../components/Productos';

const SiteRouter = () => {
    let navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('loggedIn')) {
            navigate('/login');
        }
    }, [navigate]);

    if (!localStorage.getItem('loggedIn')) {
        return <Navigate to="/login" />;
    }
    return (
        <>
            <Slider />
            <div>
                <Routes>
                    <Route exct path='/' element={<Inicio />} />
                    <Route exct path='/usuarios' element={<Usuarios />} />
                    <Route exct path='/roles' element={<Roles />} />
                    <Route exct path='/permisos' element={<Permisos />} />
                    <Route exct path='/grupos' element={<Grupos />} />
                    <Route exct path='/tiposDocumentos' element={<TipoDocumentos />} />
                    <Route exct path='/productos' element={<Productos />} />
                    
                    <Route
                        path="*"
                        element={<Navigate to="/" replace />}
                    />
                </Routes>
            </div>
        </>
    )
}

export default SiteRouter