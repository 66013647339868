

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/style.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/tinymce/tinymce.min.js'
import 'bootstrap/dist/js/main.js'
import 'animate.css/animate.css'
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AppRouter from './routers/AppRouter';


function App() {
  return (
    <div>
      <AppRouter/>
    </div>
  );
}

export default App;
