import React from 'react';
import { usuarios } from './Data/Administracion';

const Usuarios = () => {
  return (
    <main id="main" className="main">
      <div className='container-fluid'>
        <div className="row mt-2 text-start">
          <div className="pagetitle">
            <h1>Usuarios</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Usuarios</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Corporativo</th>
                <th scope="col">Nombre Completo</th>
                <th scope="col">Puesto</th>
                <th scope="col">Grupo</th>
                <th scope="col">Activo</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((usuario, index) => (
                <tr key={index}>
                  <td><button className='btn btn-primary btn-sm'>{usuario.corporativo}</button></td>
                  <td>{usuario.nombreCompleto}</td>
                  <td>{usuario.puesto}</td>
                  <td>{usuario.grupo}</td>
                  <td>{usuario.activo ? 'Activo' : 'Inactivo'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};

export default Usuarios;
