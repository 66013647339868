const usuarios = [
    {
        corporativo: 63201,
        nombreCompleto: "Miguel Angel Castro De Leon",
        puesto: "Ejecutivo Junior",
        grupo: "Ejecutivos Junior",
        activo: true
    },
    {
        corporativo: 63202,
        nombreCompleto: "Eswin Alejandro Ixcol Castro",
        puesto: "Ejecutivo Junior",
        grupo: "Ejecutivos Junior",
        activo: true
    },
    {
        corporativo: 63203,
        nombreCompleto: "Ana Maria Lopez Perez",
        puesto: "Ejecutivo Junior",
        grupo: "Ejecutivos Junior",
        activo: true
    },
    {
        corporativo: 63204,
        nombreCompleto: "Juan Carlos Ramirez Garcia",
        puesto: "Ejecutivo Junior",
        grupo: "Ejecutivos Junior",
        activo: true
    },
    {
        corporativo: 63205,
        nombreCompleto: "Maria Gonzalez Martinez",
        puesto: "Ejecutivo Senior",
        grupo: "Ejecutivos Senior",
        activo: true
    },
    {
        corporativo: 63206,
        nombreCompleto: "Pedro Hernandez Lopez",
        puesto: "Analista de Datos",
        grupo: "Analistas",
        activo: true
    },
    {
        corporativo: 63207,
        nombreCompleto: "Laura Perez Rodriguez",
        puesto: "Gerente de Proyectos",
        grupo: "Gerencia",
        activo: true
    },
    {
        corporativo: 63208,
        nombreCompleto: "Luis Miguel Rodriguez",
        puesto: "Desarrollador Full Stack",
        grupo: "Desarrollo",
        activo: false
    },
    {
        corporativo: 63209,
        nombreCompleto: "Carolina Gomez Sanchez",
        puesto: "Diseñador UX/UI",
        grupo: "Diseño",
        activo: true
    },
    {
        corporativo: 63210,
        nombreCompleto: "Sofia Hernandez",
        puesto: "Especialista en Marketing Digital",
        grupo: "Marketing",
        activo: true
    }
];


const roles = [
    {
        codigoRol: 1,
        nombreRol: "Administrador",
        descripcion: "Rol para administradores"
    },
    {
        codigoRol: 2,
        nombreRol: "Gerencias",
        descripcion: "Rol para gerencias."
    },
    {
        codigoRol: 3,
        nombreRol: "Supervisores",
        descripcion: "Rol para supervisores"
    },
    {
        codigoRol: 4,
        nombreRol: "Analistas",
        descripcion: "Rol para analistas."
    }
];


const permisos = [
    {
        codigoPermiso: 1,
        nombrePermiso: "Editar Solicitudes",
        descripcionPermiso: "puede cambiar la informacion de las solicitudes."
    },
    {
        codigoPermiso: 2,
        nombrePermiso: "Carga De Documentos",
        descripcionPermiso: "Puede cargar documentos"
    },
    {
        codigoPermiso: 3,
        nombrePermiso: "Elimina Documentos",
        descripcionPermiso: "Elimina Documentos."
    }

]

const grupos = [
    {
        codigoGrupo:1,
        nombre:"Ejecutivos Jr.",
        descripcion: "Ejecutivos que ingresan solicitudes"
    },
    {
        codigoGrupo:2,
        nombre:"Ejecutivos Sr.",
        descripcion: "Ejecutivos que revisan Solicitudes"
    }
    
]

const tipoDocumentos = [
    {
        id: 1,
        nombre: "Documento Identificación",
        descripcion: "documento que se utiliza para identificacion del prospecto",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 2,
        nombre: "Solicitud Crédito",
        descripcion: "Solicitud de crédito.",
        aplicaGlobal: false,
        aplicaFechaVencimiento: true
    },
    {
        id: 3,
        nombre: "Pasaporte",
        descripcion: "documento internacional de identidad.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 4,
        nombre: "Licencia de Conducir",
        descripcion: "documento que autoriza a una persona a conducir vehículos.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 5,
        nombre: "Tarjeta de Identificación Laboral",
        descripcion: "documento que identifica a una persona como empleado de una empresa.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: false
    },
    {
        id: 6,
        nombre: "Carnet de Estudiante",
        descripcion: "documento que identifica a una persona como estudiante de una institución educativa.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 7,
        nombre: "Documento de Residencia",
        descripcion: "documento que certifica la residencia legal de una persona en un país.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 8,
        nombre: "Documento de Matrimonio",
        descripcion: "documento que certifica el matrimonio civil de una pareja.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 9,
        nombre: "Documento de Divorcio",
        descripcion: "documento que certifica el divorcio de una pareja.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 10,
        nombre: "Certificado de Nacimiento",
        descripcion: "documento que certifica el nacimiento de una persona.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 11,
        nombre: "Certificado de Defunción",
        descripcion: "documento que certifica el fallecimiento de una persona.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 12,
        nombre: "Certificado de Antecedentes Penales",
        descripcion: "documento que certifica la existencia o ausencia de antecedentes penales de una persona.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 13,
        nombre: "Documento de Autorización de Viaje",
        descripcion: "documento que autoriza a una persona a viajar, especialmente si es menor de edad y viaja sin la compañía de uno o ambos padres.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    },
    {
        id: 14,
        nombre: "Documento de Identificación Fiscal",
        descripcion: "documento que identifica a una persona o entidad como contribuyente fiscal.",
        aplicaGlobal: true,
        aplicaFechaVencimiento: true
    }
];

const productos = [
    {
        codigoProducto: 1,
        producto: "Grandes Deudores",
        descripcion: "Solicitudes para personas individuales y jurídicas."
    },
    {
        codigoProducto: 2,
        producto: "Préstamo Personal",
        descripcion: "Préstamos destinados a necesidades personales, como viajes, estudios, etc."
    },
    {
        codigoProducto: 3,
        producto: "Tarjeta de Crédito",
        descripcion: "Tarjetas que permiten realizar compras y disponer de efectivo con un límite de crédito establecido."
    },
    {
        codigoProducto: 4,
        producto: "Cuenta de Ahorro",
        descripcion: "Cuentas bancarias que permiten ahorrar dinero y generar intereses."
    },
    {
        codigoProducto: 5,
        producto: "Seguro de Vida",
        descripcion: "Seguros que brindan protección financiera a la familia del asegurado en caso de fallecimiento."
    }
];

export {
    usuarios,roles,permisos,grupos,
    tipoDocumentos,productos
}