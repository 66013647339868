import {grupos} from './Data/Administracion'

const Grupos = () => {
  return (
    <main id="main" className="main">
      <div className='container-fluid'>
        <div className="row mt-2 text-start">
          <div className="pagetitle">
            <h1>Grupos</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Grupos</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Grupo</th>
                <th>Descripcion</th>
              </tr>
            </thead>
            <tbody>
              {grupos.map((grupo, index) => (
                <tr key={index}>
                  <td><button className='btn btn-primary btn-sm'>{grupo.codigoGrupo}</button></td>
                  <td>{grupo.nombre}</td>
                  <td>{grupo.descripcion}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  )
}

export default Grupos