import React from 'react'

const Inicio = () => {

  return (
    <main id="main" className="main">
      <div className='container-fluid'>
        <div className="row mt-2 text-start">
          <div class="pagetitle">
            <h1>Inicio</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Inicio</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Inicio