import { permisos } from "./Data/Administracion"

const Permisos = () => {
  return (
    <main id="main" className="main">
      <div className='container-fluid'>
        <div className="row mt-2 text-start">
          <div className="pagetitle">
            <h1>Permisos</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Permisos</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <th scope='col'>ID</th>
                <th scope='col'> Permiso</th>
                <th scope='col'> Descripcion</th>
              </tr>
            </thead>
            <tbody>
              {permisos.map((permiso, index) => (
                <tr key={index}>
                  <td><button className='btn btn-primary btn-sm'>{permiso.codigoPermiso}</button></td>
                  <td>{permiso.nombrePermiso}</td>
                  <td>{permiso.descripcionPermiso}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  )
}

export default Permisos