import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Login from '../components/Login'
import SiteRouter from './SiteRouter'; 


const AppRouter = () => {
return (
  <Router>
      <div>

          <Routes>
              <Route  path='/login' element={<Login />}/>
              <Route  path='/*' element={<SiteRouter/>}/>
          </Routes>

      </div>
  </Router>
)
}

export default AppRouter;